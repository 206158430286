@value colors: '~css/colors.css';
@value _white, _steel-shadow-light, _gray-darker, _bulletn-blue from colors;

.accountNav {
  margin-bottom: 20px;
}

.ul {
  list-style: none;
  padding-left: 0;
}

.li {
  margin-left: 0;
}

.li + .li {
  border-top: 1px solid _steel-shadow-light;
}

.navLink {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  font-family: 'Lato', 'Open Sans', sans-serif;
  color: _gray-darker;
  font-size: 16px;
}

.navLink:hover {
  color: _bulletn-blue;
}

.selected {
  font-weight: bold;
}
