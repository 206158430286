@value colors: '~css/colors.css';
@value _gray, _gray-darker, _jade, _white from colors;

.root {
  padding: 0px 20px 5px 10px;
}

.hintHeading {
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  color: _gray-darker;
  margin-bottom: 5px;
}

.hintList {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 10px;
}

.hintItem {
  margin-top: 7px;
}

.hintItem::before {
  content: ' ';
  background: _gray;
  margin-right: 5px;
  height: 6px;
  width: 6px;
  display: inline-block;
  border-radius: 50%;
}

.verified::before {
  background: _jade;
}
