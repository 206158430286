.rdt .rdtPicker {
  width: 100%;
}

.rdt .rdtPicker .dow {
  color: #6b8089;
  font-size: 8pt;
  letter-spacing: 0.71px;
  text-transform: uppercase;
}
.rdt .rdtPicker th {
  border: none;
}

.rdt .rdtPicker td {
  color: #6b8089;
  height: 40px;
  font-size: 9pt;
}

.rdt .rdtPicker td.rdtOld,
.rdt .rdtPicker td.rdtNew {
  color: #d7dfe1;
}

.rdt .rdtPicker td.rdtDisabled {
  cursor: default;
  color: #d7dfe1;
}

.rdt .rdtPicker td.rdtActive,
.rdt .rdtPicker td.rdtActive:hover {
  background: #0079a7;
  color: white;
  text-shadow: none;
}