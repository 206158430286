@value variables: '~css/variables.css';
@value _zindex-toast from variables;

.root {
  position: fixed;
  bottom: 0;
  left: 10px;
  padding: 0;
  z-index: _zindex-toast;
}
