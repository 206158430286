.headerImageWrapper {
  overflow: hidden;
  cursor: pointer;
}

.headerImageWrapper-accepted {
  background-color: #00c7b2;
  opacity: 0.5;
}

.headerImageWrapper h1 {
  position: relative;
  top: -300px;
  margin-left: 50px;
  color: white;
  font-family: 'Playfair Display';
  font-weight: 800;
  font-size: 64px;
}

.headerImageWrapper img {
  width: 100%;
}

.loadingHeaderImage {
  opacity: 0.3;
}
