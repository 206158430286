@value colors: '~css/colors.css';
@value _gray, _gray-dark from colors;

.select {
  width: 100%;
  border: 1px solid _gray;
  height: 33px;
  padding: 5px 32px 5px 7px;
  font-size: 14px;
  appearance: none;
  position: relative;
}

.selectWrapper {
  position: relative;
}

.selectWrapper::after {
  content: '';
  border: solid _gray-dark;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  right: 15px;
  top: calc(50% - 6px);
  transform: rotate(45deg);
}

.selectWrapper.open:after {
  transform: rotate(45deg);
}

.selectWrapper.close:after {
  transform: rotate(45deg);
}
