@value colors: '~css/colors.css';
@value _white, _steel-shadow-light from colors;

.root {
  max-width: 60rem;
  margin: 0 auto;
  position: relative;
}

@media (min-width: 725px) {
  .root {
    margin-top: 50px;
  }
}

.bulletin {
  padding: 25px;
  background: _white;
}

.body {
  background: _white;
}
