@value colors: '~css/colors.css';
@value _bulletn-blue, _gray-lighter from colors;

.root {
  width: 250px;
  background: _gray-lighter;
  border: 1px solid _bulletn-blue;
  border-radius: 5px;
  height: 150px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto 10px auto;
}

.cardNumber {
  text-align: center;
}
.bottomRow {
  display: flex;
  justify-content: space-between;
}
