@value colors: '~~css/colors.css';
@value _blue-g, _blue-g-light, _blue-g-dark from colors;
@value _steel-shadow-light from colors;

.header {
  margin-bottom: 50px;
  height: 75px;
  background: _blue-g;
}

.headerInner {
  padding-top: 35px;
  display: flex;
}

.h1 {
  margin: 10px 0px 0px 10px;
  font-size: 20px;
  color: white;
}

.h2 {
  margin-top: 0;
  margin-bottom: 15px;
}

.content {
  padding: 0 25px;
  position: relative;
  min-height: 20rem;
}

.contentDescription {
  max-width: 50rem;
  color: #6b8089;
  font-family: 'Lato', 'Open Sans', sans-serif;
  margin-bottom: 30px;
}
