@value colors: '~css/colors.css';
@value variables: '~css/variables.css';

@value _gray, _white, _steel, _steel-shadow-light, _jade, _white from colors;
@value _zindex-tooltip from variables;

.root {
  position: absolute;
  z-index: _zindex-tooltip;
  padding: 10px 13px;
  animation: fadein 0.5s;
  filter: drop-shadow(0px 5px 15px _steel-shadow-light);
}

.fixed {
  position: fixed;
}

.dark {
  color: _white;
  font-size: 12px;
  background: _steel;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.root::before {
  content: ' ';
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
}

.right::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -10px;
  top: calc(50% - 10px);
}

.light.right::before {
  border-right: 10px solid _white;
}

.dark.right::before {
  border-right: 10px solid _steel;
}

.left::before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -10px;
  top: calc(50% - 10px);
}

.light.left::before {
  border-left: 10px solid _white;
}

.dark.left::before {
  border-left: 10px solid _steel;
}

.top::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  bottom: -10px;
  left: calc(50% - 10px);
}

.light.top::before {
  border-top: 10px solid _white;
}

.dark.top::before {
  border-top: 10px solid _steel;
}

.bottom::before {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: -10px;
  left: calc(50% - 10px);
}

.light.bottom::before {
  border-bottom: 10px solid _white;
}

.dark.bottom::before {
  border-bottom: 10px solid _steel;
}

.small {
  padding: 5px 15px;
}

.small.top::before {
  border-width: 5px !important;
  left: calc(50% - 5px);
  bottom: -5px;
}

.small.bottom::before {
  border-width: 5px !important;
  left: calc(50% - 5px);
  top: -5px;
}
