@value colors: '~css/colors.css';
@value _gray, _gray-dark, _ruby-dark, _bulletn-blue from colors;

.input {
  font-family: 'Lato', 'Open Sans', sans-serif;
  width: 100%;
  height: 33px;
  padding: 5px 7px;
  font-size: 14px;
  font-weight: normal;
  margin: 0; /* to offset stripe margin it places on it's input container */
  border-radius: 0; /* to override react-datetime's border-radius */
}

.underlined {
  border: none;
  border-bottom: 2px solid _gray;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0px;
}

.underlined:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: _bulletn-blue;
}

.bordered {
  border: 1px solid _gray;
}

.large {
  width: 380px;
}

.invalid .input {
  border-color: _ruby-dark;
}

.errorMessage {
  color: _ruby-dark;
  font-size: 14px;
}
