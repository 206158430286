.sign-up-fields-form-group {
  margin-bottom: -15px;
}

.sign-up-fields-form-group input {
  border: none;
  border-bottom: 2px solid #d7dfe1;
  border-radius: 1px;
  outline: none;
  background-color: transparent;
  box-shadow: none;
  min-width: 300px;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  padding-left: 0px;
}

.sign-up-container .form-control {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none !important;
  outline: none;
}

.sign-up-container .form-control:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: #0079a7;
}

.sign-up-fields-form-group input:focus {
  outline: none;
  box-shadow: none;
}

.sign-up-fields-form-group label {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  margin-top: 30px;
}

.has-success .form-control {
  border-color: #00c7b2;
}

.has-success .form-control:focus {
  border-color: #0079a7;
}

.has-success .control-label {
  color: #00c7b2;
}

.has-success .form-control-feedback {
  color: #00c7b2;
}
