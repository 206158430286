@value colors: '~css/colors.css';
@value variables: '~css/variables.css';

@value _steel-shadow-light, _gray-lighter, _gray, _gray-darker, _white from colors;
@value _zindex-editorToolbar from variables;

.root {
  display: flex;
  z-index: _zindex-editorToolbar;
  padding: 10px 12px;
  box-shadow: 0px 2px 15px _steel-shadow-light;
  position: sticky;
  background: _white;
  bottom: -1px;
}

.button {
  border: none;
  background: none;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.button * {
  color: _gray !important;
}

.button.colorAndFill * {
  fill: _gray !important;
  color: _gray !important;
}

.button + .button {
  margin-left: 8px;
}

.button:hover {
  background: _gray-lighter;
}

.active * {
  color: _gray-darker !important;
}

.active.colorAndFill * {
  fill: _gray-darker !important;
  color: _gray-darker !important;
}

.divider {
  padding: 0 12px;
  color: _gray-lighter;
  font-size: 2.4rem;
  line-height: 2.4rem;
}
