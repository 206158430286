@value colors: '~css/colors.css';
@value _gray-dark, _steel, _gray, _gray-darker, _steel from colors;

.supportTooltip::before {
  left: calc(100% - 37px);
}

.supportBtn {
  margin-left: 20px;
  width: 25px;
  height: 25px;
  border: 1px solid _gray;
  border-radius: 50%;
  color: _steel;
  font-weight: bold;
  font-size: 1.7rem;
  font-family: 'Lato';
}

.supportParagraph1,
.supportParagraph2 {
  font-size: 1.4rem;
  margin: 0;
}

.supportParagraph1 {
  font-weight: bold;
  color: _gray-darker;
}

.email {
  color: _steel;
  font-weight: bold;
}
