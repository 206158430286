.viewsAndResponses h3 {
  font-weight: 600;
  text-align: center;
  margin-top: 5px;
}

.chartNumbers h3 {
  font-weight: 400;
  color: #6b8089;
}

.recharts-wrapper {
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 25px;
}

.recharts-layer path {
  stroke: none;
}

.chartNumbers {
}

.chartNumbers svg {
  position: relative;
  top: 3px;
  margin-right: 10px;
}

h3.chartResponses {
  margin-bottom: 0px;
}
