.avatarRowLayout {
  display: flex;
  align-items: flex-start;
  padding-left: 40px;
}

.avatarColumnLayout {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 20px;
}

.churchLogo {
  margin-right: 25px;
}
