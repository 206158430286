.td {
  padding: 15px 5px;
  font-size: 1.4rem;
}

.primary {
  color: #333;
  font-weight: bold;
}

.secondary {
  color: #6b8089;
}
