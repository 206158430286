/* Breadcrumbs */
.breadcrumb {
  margin-bottom: 10px;
  background-color: transparent;
}

.breadcrumb > li > a {
  color: #6b8089;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.breadcrumb > li > span {
  color: #6b8089;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Lato', 'Open Sans', sans-serif;
}
