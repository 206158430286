@value colors: '~css/colors.css';
@value _gray-light from colors;

.formRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.formRow:first-of-type {
  padding-top: 25px;
}

.formRow:not(:last-of-type) {
  border-bottom: 1px solid _gray-light;
}

.inputContainer {
  width: 65%;
  max-width: 450px;
  min-width: 250px;
}
