.sign-up-stage-indicator {
  height: 15px;
  width: 15px;
  background-color: #f3f4f6;
  border-radius: 50%;
  display: inline-block;
  margin: 25px;
  transition: 1s;
}

.sign-up-stage-indicator-active {
  background-color: #0179a7;
}
