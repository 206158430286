.profilePicture {
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid white;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  background-color: white;
}

.profilePicture > img {
  height: 100%;
}
