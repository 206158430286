.consoleHeaderContainer {
  padding: 30px 30px 30px 40px;
}

.consoleHeader {
  display: flex;
  align-items: center;
}

.consoleHeader h2, .consoleHeader p { 
  margin: 0 0 0 10px;
}