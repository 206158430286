@value variables: '~css/variables.css';
@value _zindex-loadingIcon from variables;

.root {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 3px;
}
