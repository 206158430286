@value colors: '~css/colors.css';
@value variables: '~css/variables.css';

@value _steel-shadow-dark from colors;
@value _zindex-modalTooltip from variables;

.formControl {
  margin-bottom: 20px;
}

.label {
  margin-right: 5px;
}

.labelWithIcon {
  display: flex;
}

.tooltip {
  max-width: 200px;
  z-index: _zindex-modalTooltip;
}

.icon:focus circle,
.icon:hover circle {
  fill: #1f9bcc;
}

.icon:focus text,
.icon:hover text {
  fill: white;
}
