@value colors: '~css/colors.css';
@value _gray-darker from colors;

.root {
  display: flex;
  flex-direction: column;
  margin-top: -75px; /* offset some of the whitespace in the tumbleweed gif */
  width: 100%;
  max-width: 30rem;
}

.header,
.description {
  font-family: 'Lato';
  text-align: center;
}

.header {
  font-size: 2.2rem;
  color: _gray-darker;
}
