.resetPasswordInput {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  border: none;
  border-bottom: 1px solid #bbb;
  border-radius: 1px;
  padding: 10px 0px;
  outline: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-size: 16px;
}

.resetPasswordInput:focus {
  border-bottom: 1px solid #0079a7;
}
