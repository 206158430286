@value colors: '~css/colors.css';
@value _bulletn-blue, _gray, _white, _selection from colors;

.root {
  padding: 10px 0;
}

.hr {
  width: 100%;
  border-color: _gray;
  margin: 0;
}

.selected {
  background: _selection;
}

.selected .hr {
  border-color: _white;
}
