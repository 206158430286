@value colors: '~css/colors.css';
@value _gray-lighter from colors;
@value _bulletn-blue from colors;
@value _steel from colors;
@value _black from colors;

.newNavbar {
  display: flex;
  justify-content: space-between;
  background: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.navLinksWrapper {
  display: flex;
  width: 45%;
}

.leftLinks .newNavbarItem {
  width: 250px;
}

.rightLinks {
  justify-content: flex-end;
  align-items: center;
  padding-right: 40px;
}

.newNavbarItem {
  display: block;
  text-align: center;
  height: 60px;
  padding-top: 20px;
  font-size: 16px;
  font-weight: 600;
  color: _steel;
}

.newNavbarItem:hover {
  color: _bulletn-blue;
}

.activeNavItem {
  border-bottom: 4px solid _bulletn-blue;
  color: _bulletn-blue;
  transition: 200ms;
}

.newNavbarItem:active {
  background: _gray-lighter;
}

.newNavbarItem a {
  color: _black;
}

.newNavbarItem a:active {
  font-weight: 600;
}

.navProfileName {
  list-style: none;
}

.navProfileName ul a {
  padding: 20px;
}

.newNavbarLogo img {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  height: 60px;
  padding: 10px;
  display: block;
}
