@value colors: '~css/colors.css';
@value _white, _gray-lighter, _gray, _gray-darker, _ruby from colors;

.root {
  position: relative;
  margin: -10px -35px 0 0;
  padding: 10px 35px 0 0;
}

.root.show {
  background: linear-gradient(210deg, _gray-lighter 0%, transparent 40%);
}

.menuTooltip {
  padding-top: 7px;
  padding-bottom: 7px;
}

.menuTooltip::before {
  left: auto;
  right: 7px;
}

.menuIcon {
  position: absolute;
  right: 1px;
  top: 1px;
}

.menuIcon:hover {
  background: none;
}

.menuIcon:hover {
  background: none;
}

.menuIcon:focus svg *,
.menuIcon:hover svg * {
  fill: _gray-darker !important;
}

.delete,
.confirmDelete {
  margin-left: 8px;
}
.confirmDelete,
.confirmDelete:hover {
  background: _ruby;
}

.confirmDelete:focus {
  outline: none;
}

.confirmDelete svg * {
  fill: _white !important;
}
