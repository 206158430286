@value colors: '~css/colors.css';
@value _white, _bulletn-blue, _bulletn-blue-light, _gray, _steel, _steel-shadow-darker from colors;

.weekDayPickerContainer {
  display: grid;
  grid-template-columns: 35px 35px 35px 35px 35px 35px 35px;
  grid-gap: 12px;
  margin-top: 10px;
  text-align: center;
}

/* visually hide the radio button */
.weekDayInput {
  position: absolute;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.weekDayDisplay {
  background: _white;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
  font-weight: bold;
  color: _steel;
  cursor: pointer;
  text-align: center;
}

.weekDayInput:checked + .weekDayDisplay {
  border-radius: 50%;
  background: _bulletn-blue;
  color: white;
}

.weekDayInput:focus + .weekDayDisplay {
  background: linear-gradient(135deg, #78c3e0 0%, #67b0ce 100%);
}
