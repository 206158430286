@value colors: '~css/colors.css';
@value _white, _gray-lighter, _gray, _gray-light, _gray-dark, _gray-darker, _steel, _bulletn-blue-light from colors;

@value variables: '~css/variables.css';
@value _zindex-tooltipSelect from variables;

.root {
  display: flex;
  border-radius: 5px;
  position: relative;
  margin: -5px 0px -5px -10px;
}

.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px 5px 7px;
  width: 4.2rem;
  background: none;
  border: none;
  border-right: 1px solid _gray;
  border-radius: 0;
}

.button svg {
  margin-right: 0;
}

.ul {
  box-shadow: 55px 0px 15px 0px _white;
  background: _white;
  position: absolute;
  z-index: _zindex-tooltipSelect;
  border-right: 1px solid _gray;
  border-left: none;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.li {
  display: flex;
  align-items: center;
  padding: 8px 10px;
  cursor: default;
}

.focused,
.li:hover,
.li:focus {
  background: _gray-lighter;
}
