@value colors: '~css/colors.css';
@value _gray, _ruby-dark from colors;

.formContainer {
  display: flex;
  justify-content: center;
}

.form {
  max-width: 500px;
  width: 100%;
}

.formRow,
.bottomRow {
  margin-bottom: 20px;
}

.bottomRow {
  display: grid;
  grid-template-columns: calc(50% - 10px) calc(25% - 10px) calc(25% - 10px);
  grid-column-gap: 15px;
}

.input {
  margin-top: 5px;
}

.submitRow {
  display: flex;
  justify-content: space-between;
}

.submitRow img {
  align-self: center;
}

.stripeInvalid {
  border: 1px solid _ruby-dark;
}
