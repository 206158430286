@value colors: '~css/colors.css';

@value _steel, _steel-dark, _gray-light, _gray from colors;

.root {
  padding: 5px 10px;
  border-radius: 0;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: _gray-light;
}

.button {
  text-transform: uppercase;
  font-weight: 600;
  color: _steel;
  font-size: 0.95rem;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  padding: 7px 12px;
}

.button * {
  fill: _gray !important;
}

.button:hover,
.button:focus {
  color: _steel-dark;
}

.active * {
  fill: _steel !important;
}

.divider {
  padding: 0px 5px;
}
