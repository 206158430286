.modal {
  top: 2%;
}

.modal.open {
  top: 5%;
}

.modalBody {
  max-height: 600px;
  overflow-y: auto;
  padding: 0;
}

.modal .bulletin {
  padding: 25px;
}

.headerImg {
  max-width: 100%;
}
