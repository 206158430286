@value colors: '~css/colors.css';
@value _bulletn-blue, _white, _gray from colors;

.root {
  position: relative;
  width: 100%;
  background: _bulletn-blue;
  text-align: center;
  padding: 30px 60px;
}

.line1,
.line2 {
  color: _white;
  line-height: 1;
}

.line2 {
  font-size: 1.5rem;
  opacity: 0.85;
}

.dismiss {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}
