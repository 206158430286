.loadingIcon {
  position: fixed;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  width: 100%;
  padding: 10px 14px;
  box-shadow: none;
  background: transparent;
  border-bottom: 2px solid #d7dfe1;
  border-radius: 1px;
  transition: 0.5s;
}

.StripeElement--focus {
  border-bottom: 2px solid #0079a7;
}

.payment-form-row label {
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  font-family: 'Lato', sans-serif;
  margin: 0 15px;
}

.payment-form-row {
  display: flex;
  flex-direction: row;
  min-width: 482px;
}

.payment-error-message {
  color: #fc5e54;
  position: absolute;
  margin-top: 250px;
}
