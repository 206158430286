@keyframes flashHalf {
  0% {
    fill: #fc5e54;
  }
  40% {
    fill: #ffa9a4;
  }
  100% {
    fill: #fc5e54;
  }
}
@keyframes flashFull {
  0% {
    fill: #fc5e54;
  }
  40% {
    fill: #ffeceb;
  }
  100% {
    fill: #fc5e54;
  }
}

.flashingInner {
  animation: flashHalf 3s infinite;
}
.flashingOuter {
  animation: flashFull 3s infinite;
}
