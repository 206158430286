@value colors: '~css/colors.css';
@value _bulletn-blue, _bulletn-blue-light from colors;

.root {
  padding: 12px 0 15px 20px;
  margin: 0 -25px;
}

.button {
  color: _bulletn-blue-light;
  border-bottom: 2px dashed _bulletn-blue-light;
  padding: 0 0 3px 0;
  margin-left: 4px;
}

.button:hover {
  color: _bulletn-blue;
  border-color: _bulletn-blue;
}
