@value colors: '~css/colors.css';
@value _bulletn-blue, _gray-darker from colors;

.bulletinHeader {
  width: 100%;
  position: fixed;
  max-width: 60rem;
  z-index: -1;
  text-align: center;
}

.headerText {
  font-family: 'Oswald';
  font-weight: 600;
  text-transform: uppercase;
  font-size: 38px;
  color: _gray-darker;
  padding: 10px;
  letter-spacing: 1px;
}

.headerDate {
  font-family: 'Oswald';
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 20px;
  color: _bulletn-blue;
}
