@value colors: '~css/colors.css';
@value variables: '~css/variables.css';

@value _white, _gray, _steel, _steel-shadow-dark, _jade from colors;
@value _zindex-modalBackground, _zindex-modal from variables;

.root {
  position: fixed;
  top: 5%;
  opacity: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 5px 300px _steel-shadow-dark;
  width: 400px;
  background: _white;
  transition-property: top opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease;
}

.root.open {
  top: 12%;
  opacity: 1;
  z-index: _zindex-modal;
}

.background {
  position: fixed;
  background: _white;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
}

.background.open {
  opacity: 0.5;
  z-index: _zindex-modalBackground;
}

.header {
  position: relative;
  background: linear-gradient(135deg, #1f9bcc 0%, #007aab 100%);
  margin-top: 0px;
  padding: 25px 50px 25px 25px;
  border-radius: 5px 5px 0 0;
}
.body {
  padding: 40px 40px 0 40px;
}

.headerText {
  display: flex;
  align-items: center;
}
.headerIcon {
  display: flex;
  background: #0079a7;
  border-radius: 50%;
  padding: 4px;
  margin-right: 15px;
}
.headerIcon svg * {
  fill: _white !important;
}

.headerText h2 {
  color: white;
  padding-right: 30px;
  margin: 0;
}
.dismiss {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}
.dismiss:focus {
  outline: 1px solid _white;
}

.footer {
  padding: 20px;
  display: flex;
  justify-content: center;
}
