@value colors: '~css/colors.css';
@value _bulletn-blue from colors;

.fieldRow + .fieldRow {
  margin-top: 20px;
}

.forgotPassword {
  color: _bulletin-blue;
  margin-bottom: 20px;
  margin-top: 10px;
}

.label {
  padding-right: 20px;
  width: 170px;
  text-align: right;
}

.formOffset {
  padding-left: 170px;
}
