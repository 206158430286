@value colors: '~css/colors.css';
@value _gray-darker , _bulletn-blue-light, _bulletn-blue, _selection, _white from colors;

.bulletin h1,
.bulletin h2,
.bulletin p,
.bulletin blockquote,
.bulletin ol,
.bulletin ul {
  margin: 0;
}

/* Normalize the selection styling so that we can make the selection
 * styling for DividerNode consistent with the rest of the selection styling
 * (DividerNode needs custom styling)
*/
.bulletin *::selection {
  background: _selection;
  color: _white;
}

.bulletin h1 {
  font-family: 'Oswald';
  font-size: 26px;
  color: #111111;
  font-style: normal;
  line-height: 30px;
  letter-spacing: -0.25px;
  text-rendering: optimizeLegibility;
  font-weight: 600;
  text-size-adjust: 100%;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
}

.bulletin h2 {
  font-family: 'Lato';
  font-size: 20px;
  color: #111111;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.17;
  line-height: 30px;
  text-rendering: optimizeLegibility;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
}

.bulletin {
  font-size: 16px;
  color: _gray-darker;
  font-style: normal;
  line-height: 26px;
  text-rendering: optimizeLegibility;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  padding-bottom: 75px;
}

.bulletin p {
  color: _gray-darker;
  font-size: 18px;
}

.bulletin blockquote {
  font-family: 'Playfair Display', serif;
  font-size: 26px;
  text-align: center;
  font-style: italic;
  letter-spacing: -0.3px;
  line-height: 36px;
  border: none;
  text-rendering: optimizeLegibility;
  word-break: break-word;
  word-wrap: break-word;
  -webkit-font-smoothing: antialiased;
  position: relative;
  padding: 25px;
}

.bulletin blockquote:before,
.bulletin blockquote:after {
  border-bottom: 1px solid #d1d1d1;
  box-sizing: border-box;
  content: '';
  height: 1px;
  left: 25%;
  position: absolute;
  right: 25%;
  top: 0;
  text-align: center;
}
.bulletin blockquote:after {
  bottom: 0;
  top: 100%;
}

.dropzone {
  width: 100%;
}

.bulletin a {
  font-size: 16px;
  font-weight: bold;
  color: _bulletn-blue-light;
}

/* Spacing Rules */
.bulletin li + li {
  margin-top: 8px;
}

.h1-after-h1,
.h1-after-blockquote,
.h1-after-img,
.h1-after-divider,
.img-after-img,
.img-after-divider,
.h2-after-blockquote,
.blockquote-after-bodyText,
.bodyText-after-blockquote {
  margin-top: 54px;
}

.h1-after-h2,
.img-after-h1,
.img-after-h2,
.img-after-bodyText {
  margin-top: 44px;
}

.h1-after-bodyText {
  margin-top: 56px;
}

.h2-after-h2,
.h2-after-img,
.h2-after-divider {
  margin-top: 39px;
}

.h2-after-h1,
.h2-after-bodyText {
  margin-top: 30px;
}

.bodyText-after-h1 {
  margin-top: 8px;
}

.bodyText-after-h2 {
  margin-top: 6px;
}

.bodyText-after-bodyText,
.blockquote-after-h1,
.blockquote-after-h2 {
  margin-top: 29px;
}

.bodyText-after-img,
.bodyText-after-divider {
  margin-top: 38px;
}

.blockquote-after-blockquote {
  margin-top: 24px;
}

.blockquote-after-img,
.blockquote-after-divider {
  margin-top: 64px;
}

.img-after-blockquote {
  margin-top: 68px;
}

.h1-after-divider,
.h2-after-divider,
.bodyText-after-divider,
.blockquote-after-divider,
.img-after-divider {
  margin-top: 32px;
}

.divider-after-h1,
.divider-after-h2,
.divider-after-bodyText,
.divider-after-blockquote,
.divider-after-img {
  margin-top: 42px;
}
