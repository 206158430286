@value colors: '~css/colors.css';
@value _white from colors;

.formControl {
  margin-bottom: 25px;
}

.select {
  align-self: start;
}
