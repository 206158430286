@value colors: '~css/colors.css';
@value _white, _yellow, _bulletn-blue, _ruby, _jade from colors;

.root {
  height: 80px;
  width: 400px;
  border-left: 5px solid;
  background-color: _white;
  display: flex;
  margin-top: 10px;
  margin-left: 0;
  padding-left: 0;
  position: relative;
  border-radius: 0;
}

.toastIcon,
.close {
  flex: 0 0 60px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.close {
  flex: 0 0 70px;
}

.contentContainer {
  flex: 1 0 270px;
  padding: 10px 0px;
  align-self: center;
  max-height: 80px;
  overflow: hidden;
}

.content {
  align-self: center;
  font-size: 14px;
  padding: 0px 5px;
  overflow: hidden;
  max-height: 60px;
}

.content .message {
  font-size: 14px;
  margin: 0;
}

.warning {
  border-left-color: _yellow;
}

.info {
  border-left-color: _bulletn-blue;
}

.success {
  border-left-color: _jade;
}

.error {
  border-left-color: _ruby;
}
