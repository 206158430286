@value colors: '~css/colors.css';
@value _gray-light, _gray-darker from colors;

.resourceRow {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 15px 0;
}

.resourceRow:first-of-type {
  padding-top: 5px;
}

.resourceRow:not(:last-of-type) {
  border-bottom: 1px solid _gray-light;
}

.resourceName {
  margin-bottom: 0;
  font-weight: bold;
  color: _gray-darker;
}
