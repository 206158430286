.Collapsible__trigger {
  font-family: 'Proxima Nova', 'Montserrat', Arial, sans-serif;
  display: block;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  color: #333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  transition: 300ms;
}

.Collapsible__trigger:after {
  display: inline-block;
  float: right;
  position: relative;
  top: -5px;
  content: ' ';
  background-image: url('http://gorrelldesign.com/img/down-arrow.svg');
  background-size: 25px 25px;
  height: 25px;
  width: 25px;
  transition: transform 500ms;
}

.Collapsible__trigger.is-open {
  padding-bottom: 15px;
  transition: 300ms;
  opacity: 1;
}

.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
}

.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

.Collapsible__contentOuter {
  transition: height 4s linear;
}

.Collapsible:after {
  border: 1px solid red;
  height: 2px;
  width: 100%;
  display: block;
}

.tileDropdown input {
}

.tileDropdown label {
}

.collapsedOption {
  margin-left: 10px;
}

.collapsedOption input {
  display: inline;
}

.collapsedOption label {
  font-family: 'Proxima Nova', 'Montserrat', Arial, sans-serif;
  font-weight: 600;
  font-size: 16px;
  width: inherit;
  display: inline-block;
  color: #6b8089;
  position: relative;
  left: 15px;
  margin-top: 20px;
}
