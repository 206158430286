.loginForm {
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 30px 0;
}

.loginFormRow {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.loginFormButton {
  margin-top: 10px;
}

.loginError {
  color: white;
  background-color: #fc5d54;
  padding: 15px 20px;
  border-radius: 3px;
  text-align: center;
}
