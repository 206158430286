@value colors: '~css/colors.css';
@value variables: '~css/variables.css';

@value _steel-shadow-light, _gray-lighter, _gray, _gray-dark, _gray-darker, _steel, _steel-dark, _white from colors;

.button + .button {
  margin-left: 8px;
}

.svgColorOnly:hover,
.svgColorAndFill:hover {
  background: _gray-lighter;
}

.svgColorOnly,
.svgColorAndFill {
  border: none;
  background: none;
  padding: 2px 4px;
  border-radius: 2px;
  display: flex;
  align-items: center;
}

.svgColorOnly * {
  color: _gray !important;
}

.svgColorOnlyActive * {
  color: _gray-darker !important;
}

.svgColorAndFill * {
  fill: _gray !important;
  color: _gray !important;
}

.svgColorAndFillActive * {
  fill: _gray-darker !important;
  color: _gray-darker !important;
}

.svgColorAndFill:disabled svg {
  fill: _gray-light !important;
  color: _gray-light !important;
}

.svgColorAndFill:disabled:hover {
  background: _white;
}

.textButton {
  text-transform: uppercase;
  font-weight: 600;
  color: _steel;
  font-size: 0.95rem;
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  padding: 7px 12px;
}

.textButton:hover,
.textButton:focus {
  color: _steel-dark;
}
