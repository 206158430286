.church-plan-display-label {
  width: 150px;
  height: 225px;
  margin: 7.5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 25px;
  cursor: pointer;
}

.church-plan-display-label h3 {
  margin-top: 10px;
  margin-bottom: 0px;
}

.church-plan-display-label p {
  margin-bottom: 0px;
}

.church-plan-radio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.church-plan-radio:checked + label {
  box-shadow: 0 5px 15px 0 rgba(107, 128, 137, 0.27);
  transition: box-shadow 0.3s;
}

.body-text-small {
  font-family: 'Lato', 'Open Sans', sans-serif;
  font-size: 1.4rem;
  line-height: 20px;
}
