.smallDropdown ul.dropdown-menu.dropdown-menu-right {
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.2);
  border: none;
  border-radius: 5px;
}

.smallDropdown .btn-smallDropdown {
  border: none;
  padding: 0px;
  opacity: 0.5;
  min-width: 50px;
  background: none;
}

.smallDropdown .btn-smallDropdown:hover {
  background: none;
}

.menu-item {
  max-width: 30px;
}

.open > .dropdown-menu {
  animation-name: slidenavAnimation;
  animation-duration: 0.3s;
  animation-iteration-count: 1;
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  -webkit-animation-name: slidenavAnimation;
  -webkit-animation-duration: 0.3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: ease;
  -webkit-animation-fill-mode: forwards;

  -moz-animation-name: slidenavAnimation;
  -moz-animation-duration: 0.3s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: ease;
  -moz-animation-fill-mode: forwards;
}
@keyframes slidenavAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes slidenavAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.smallDropdown .dropdown-menu > li > a {
  padding-top: 0px;
  padding-bottom: 3px;
  transition: 0.5s;
}

.smallDropdown .dropdown-menu > li > a:hover {
  background: none;
}
.smallDropdown .dropdown-menu:hover > li > a:not(:hover) {
  opacity: 0.5;
}

.smallDropdown .dropdown-menu > li > a > h5 > svg {
  fill: #6b8089;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.smallDropdown .btn-smallDropdown svg {
  fill: #6b8089;
  stroke: none;
  width: 50px;
}

.smallDropdown .btn-smallDropdown:focus svg {
  fill: #6b8089;
  stroke: none;
}

.smallDropdown .btn-smallDropdown:active svg {
  fill: #6b8089;
  stroke: none;
}

.btn-group.open .dropdown-toggle {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.btn-toolbar .btn-group {
  float: right;
}

.smallDropdown .btn-group.open {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.smallDropdown .btn-smallDropdown:hover {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
  opacity: 1;
}

.smallDropdown .btn-smallDropdown:active {
  background: none;
}

.smallDropdown .btn-smallDropdown:focus {
  background: none;
  border: none;
}

.smallDropdown .btn-smallDropdown:active:focus {
  background: none;
  border: none;
}

.arrow-up {
  position: relative;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white;
  top: -15px;
  right: 27px;
  float: right;
}

#view h5:active,
#view h5:active svg,
#edit h5:active,
#edit h5:active svg {
  color: #3a4a50;
  fill: #3a4a50;
  transition: 0.15s;
}

#replicate h5:hover,
#replicate h5:hover svg {
  color: #00c7b2;
  fill: #00c7b2;
  transition: 0.15s;
}

#replicate h5:active,
#replicate h5:active svg {
  color: #179789;
  fill: #179789;
  transition: 0.15s;
}

#delete h5:hover,
#delete h5:hover svg {
  color: #fc5e54;
  fill: #fc5e54;
  transition: 0.15s;
}

#delete h5:active,
#delete h5:active svg {
  color: #bf4a42;
  fill: #bf4a42;
  transition: 0.15s;
}
