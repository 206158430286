@value colors: '~css/colors.css';
@value _steel from colors;

.staticValue {
  display: inline-block;
  height: 33px;
  font-size: 16px;
  color: _steel;
  font-family: 'Lato', 'Open Sans', sans-serif;
}
