@value colors: '~css/colors.css';
@value _steel-shadow-light from colors;

.tile {
  background: white;
  box-shadow: 0px 5px 15px _steel-shadow-light;
  border-radius: 5px;
}

.tileWithPadding {
  composes: tile;
  padding: 25px;
}
