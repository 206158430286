@value colors: '~css/colors.css';
@value _gray, _gray-light, _gray-lighter, _gray-dark from colors;
@value _ruby, _ruby-light, _ruby-dark from colors;
@value _blue-g, _blue-g-light, _blue-g-dark from colors;
@value _white from colors;
@value _bulletn-blue, _bulletn-blue-dark from colors;
@value _steel, _steel-dark from colors;
@value _black from colors;

:global(a:hover),
:global(a:visited),
:global(a:link),
:global(a:active) {
  text-decoration: none;
}

:global(html) {
  font-size: 10px;
}
:global(body) {
  font-size: 1.6rem;
  background-color: _gray-lighter;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

:global(h1) {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 2.4rem;
  color: _black;
}

:global(h2) {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 2rem;
  line-height: 24px;
  color: _black;
}

:global(h3) {
  font-family: 'Lato', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 20px;
  color: _black;
}

:global(h4) {
  font-family: 'Lato', 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1.2rem;
  color: _steel;
}

:global(h5) {
  font-family: 'Montserrat', 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 1rem;
  letter-spacing: 0.75px;
  line-height: 14px;
  text-transform: uppercase;
  color: _steel;
}

:global(p) {
  font-family: 'Lato', 'Open Sans', sans-serif;
  font-size: 1.6rem;
  line-height: 26px;
  color: _steel;
}

:global(a) {
  font-family: 'Lato', 'Open Sans', sans-serif;
  font-size: 1.4rem;
}

.form input[type='submit'] {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  font-size: 12pt;
  color: #0079a7;
  background: white;
  border: 1px solid _bulletn-blue;
  padding: 15px;
  margin: 50px 25px 15px 25px;
  border-radius: 50px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  max-width: 200px;
}

.form input[type='submit']:hover {
  background: _bulletn-blue;
  color: white;
}

.form input[type='submit']:focus {
  background: _bulletn-blue;
  color: white;
  outline: 0;
}

.form select {
  width: 300px;
  border: 0.5px solid _gray-dark;
  border-radius: 1px;
  text-align: center;
  padding: 10px 0px;
  outline: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12pt;
}

.invisible {
  opacity: 0;
}

/* Navbar stuff */
.navbar > .container {
  background: white;
  height: 60px;
}

.navbar-default {
  z-index: 999;
  background-color: white;
}

.navbar-brand {
  height: 60px;
  padding: 12px 20px;
}

.navbar-nav > li {
  height: 60px;
}

.navbar-nav > li:hover {
  background: _gray-lighter;
  border-bottom: 2px solid _bulletn-blue;
}

.navbar-nav > li > a {
  padding: 20px;
  text-align: center;
  font-weight: normal;
  color: _black;
}

.navbar-nav a {
  color: #828282;
  font-weight: 400;
}

.navbar-nav a:hover {
  text-decoration: none;
}

.navbar-toggle {
  border: none;
}

.navbar-collapse {
  background: white;
}

.navbar-collapse.in {
  min-height: 100vh;
}

.navbar-toggle {
  padding: 15px;
}

.dropdown-menu {
}

.dropdown-menu > li > a {
  padding: 20px;
}

.dropdown-menu > li > a:hover {
  outline: 0;
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
  height: 100vh;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: none;
}

.nav-item {
  text-align: center;
}

@media (max-width: 767px) {
  .navbar-nav > li > a {
    font-size: 2em;
  }

  .dropdown-menu > li > a {
    font-size: 1.5em;
    text-align: center;
  }

  .navbar-nav > li {
    border-bottom: none;
    height: 60px;
  }

  .navbar-nav > li:hover {
    border-bottom: none;
  }

  .navbar-nav .open .dropdown-menu > li > a {
    line-height: normal;
  }
}

/*# sourceMappingURL=main.css.map */
