/* Profile and Services */

.root {
  margin-bottom: 20px;
}

.consoleProfilePictureWrapper {
  padding-top: 15px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}

.serviceSelect {
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: -25px;
  margin-right: -25px;
}
.serviceSelect > h3 {
  font-weight: 600;
}

.serviceSelect :global(.btn) {
  color: #333;
  border: none;
  border-radius: initial;
  box-shadow: none;
  text-align: left;
  padding-left: 0px;
}

.serviceSelect :global(.btn-group),
.serviceSelect .serviceDropdown {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.serviceSelect ul a {
  padding: 20px;
}

.serviceDropdown {
  font-family: 'Lato', 'Open Sans', 'sans-serif';
}

.serviceSelect .serviceDropdown:active,
.serviceSelect :global(.open) .serviceDropdown:active,
.serviceSelect .serviceDropdown:focus,
.serviceSelect :global(.open) .serviceDropdown:focus,
.serviceSelect .serviceDropdown:hover,
.serviceSelect :global(.open) .serviceDropdown:hover {
  color: #777;
  border: none;
  background: none;
}

.serviceSelect :global(.dropdown-menu) {
  width: 100%;
  border: none;
  border-radius: 0px;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}

.bulletinTitleAndDate {
  padding-left: 15px;
}

.liveBulletin {
  display: flex;
  padding-top: 25px;
  height: 100%;
}

.liveBulletin h3 {
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
}

.liveBulletin p {
  margin-top: 0px;
  font-style: italic;
  margin-bottom: 0px;
}

.liveBulletin svg {
  margin-top: 5px;
}

.liveIndicator {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #e96565;
  display: inline-block;
  margin-top: 5px;
}
