.column1 {
  width: 23%;
  padding-left: 40px;
}

.column3 {
  width: 20%;
}

.stat {
  display: flex;
  align-items: center;
}

.statIcon {
  margin-right: 5px;
}