@value colors: '~css/colors.css';
@value _bulletn-blue from colors;

#no-match-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  text-align: center;
  flex-direction: column;
}

#no-match-404 {
  font-size: 128px;
}

#no-match-404-tile {
  width: 325px;
}

#no-match-return-tile {
  width: 325px;
  margin-top: 15px;
  padding: 10px 0px 10px 0px;
  text-align: center;
}

#no-match-return-text {
  margin: 15px 0px 15px 0px;
}

#no-match-return-tile:hover {
  background: _bulletn-blue;
  -webkit-transition: background 0.5s ease-in-out;
  transition: background 0.5s ease-in-out;
}

#no-match-return-tile:hover h3 {
  color: white;
  -webkit-transition: color 0.25s ease-in-out;
  transition: color 0.25s ease-in-out;
}
