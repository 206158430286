@value colors: '~css/colors.css';
@value _bulletn-blue, _white, _steel-shadow-light, _steel-shadow-darker, _gray-darker from colors;

.root {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border: none;
  width: 100%;
  outline: none;
}

.text {
  font-family: 'Lato', 'Open Sans', sans-serif;
  font-weight: 600;
  margin-left: 10px;
  color: _gray-darker;
  transition: all 0.5s;
  font-size: 1.5rem;
}

.root :global(svg g) {
  fill: _bulletn-blue !important;
}

.root:hover {
  box-shadow: _steel-shadow-darker;
  transform: translate(0, -1px);
}

.root:active {
  background: _bulletn-blue;
  box-shadow: _steel-shadow-light;
  transform: translate(0, 3px);
}

.root:active .text,
.active .text {
  color: white;
}

.active :global(svg g),
.root:active :global(svg g) {
  fill: _white !important;
}

.root :global(svg g) {
  transition: all 0.3s;
}

.active {
  background: _bulletn-blue;
}
