@value variables: '~css/variables.css';
@value colors: '~css/colors.css';

@value _zindex-editorToolbarTooltip from variables;
@value _white from colors;

.tooltip {
  display: flex;
  z-index: _zindex-editorToolbarTooltip;
}

.tooltipText {
  color: _white;
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
}

.hotkey {
  font-weight: normal;
  opacity: 0.5;
  font-size: 1.3rem;
}
