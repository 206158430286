@value colors: '~css/colors.css';

@value _steel, _steel-light from colors;

.input,
.input:focus {
  outline: none;
  border: none;
}

.input {
  font-size: 14px;
  flex: 1;
  color: _steel;
}

.linkTypeLabel {
  color: _steel-light;
  font-size: 1.4rem;
  padding: 1px 2px 0 10px;
}

.editLinkFormContainer {
  display: grid;
  grid-template-columns: 26rem 20px;
  grid-gap: 10px;
}

.editLinkForm {
  display: flex;
}

.root {
  transition: height 2s;
}
