.load-more-button {
  font-size: 10px;
  letter-spacing: 1px;
  text-align: center;
  color: #6b8089;
  height: 4em;
  background-color: #f3f4f6;
  letter-spacing: normal;
  display: block;
  width: 100%;
  border: 0px;
}

.load-more-button:hover {
  background-color: #6b8089;
  color: white;
}

.load-more-button:focus {
  outline: 0px;
}
