@value colors: '~css/colors.css';
@value _gray, _gray-light, _gray-lighter, _gray-dark from colors;

.h2 {
  font-family: 'Lato';
  padding: 25px;
  border-bottom: 1px solid _gray-light;
  font-size: 16px;
  font-weight: 600;
}

.info {
  padding: 25px;
}

.formControl + .formControl {
  margin-top: 20px;
}

.info :global(.rdt .rdtPicker td) {
  height: 30px;
}
