@value colors: '~css/colors.css';
@value _gray, _gray-light, _steel, _steel-dark, _white, _steel-shadow-light, _bulletn-blue-light, selection from colors;

.root {
  position: relative;
  padding: 30px 35px 0 0;
  margin-top: -30px;
}

.img {
  width: 100%;
  outline: none;
}

.full {
  width: calc(100% + 100px);
  margin: -30px 0px 0 -50px;
  padding: 30px 0 0 0;
}

.fullReadonly {
  width: calc(100% + 50px);
  margin: 0 -25px;
}

.processing {
  background: _gray-light;
  height: 25rem;
  margin: 0;
  padding: 0;
}

.errorMessage {
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 20px 80px;
  justify-content: center;
  text-align: center;
}

.errorMessageButton {
  margin-top: 10px;
}

.displayOption {
  padding: 5px 8px;
}

.remove {
  padding: 7px 19px;
}
