@value colors: '~~css/colors.css';
@value _jade, _white from colors;

.root {
  font-weight: normal;
  background: _jade;
  color: _white;
  padding: 4px 11px;
  font-size: 1.1rem;
  border-radius: 2px;
  margin-left: 5px;
}
