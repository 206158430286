/* Get ya style on here */
@value colors: '~css/colors.css';
@value _gray-dark from colors;
@value _ruby from colors;
@value _white from colors;
@value _bulletn-blue from colors;

.root {
  margin: 30px auto 30px;
  padding: 0 10px;
  width: 100%;
  max-width: 45rem;
  text-align: center;
}

.tile {
  padding: 80px;
  margin-bottom: 20px;
}

.loginBtn {
  font-weight: 700;
  font-size: 16px;
  color: _bulletn-blue;
  background: _white;
  border: 1px solid _bulletn-blue;
  padding: 15px;
  margin: 50px auto 15px;
  border-radius: 50px;
  transition: 0.5s;
  width: 100%;
  max-width: 20rem;
}

.loginBtn:hover {
  background: _bulletn-blue;
  color: _white;
}

.loginBtn:focus {
  background: _bulletn-blue;
  color: _white;
  outline: 0;
}

.monogram {
  width: 60px;
  margin-top: 15px;
}

.formControl {
  margin-top: 35px;
}

.input {
  text-align: center;
  border-width: 0.5px;
  transition: border 0.5s;
}

.input:focus {
  border-width: 2px;
}

.label {
  margin-bottom: 15px;
  color: _gray-dark;
  font-weight: normal;
  font-size: 1.4rem;
}

.forgotPassword {
  text-decoration: none;
  font-size: 10pt;
  font-style: italic;
  color: _gray-dark;
  cursor: pointer;
}

.label,
.forgotPassword,
.loginBtn {
  font-family: 'Source Sans Pro', 'sans-serif';
}

.loginInfoTile {
  color: _white;
  background-color: _bulletn-blue;
  padding: 15px 20px;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 25px;
  max-width: 450px;
}

.loginError {
  color: _white;
  background-color: _ruby;
  padding: 15px 20px;
  border-radius: 3px;
  text-align: center;
  margin-bottom: 25px;
}

.loginSignup {
  display: block;
  color: _bulletn-blue;
  transition: color, background 0.5s ease-in-out;
  background: none;
  padding: 15px;
  border-radius: 3px;
  margin: 25px 25px 0 25px;
  border-radius: 3px;
}

.loginSignup:hover {
  color: _white;
  background: _bulletn-blue;
  text-decoration: none;
}
