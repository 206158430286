@value colors: '~css/colors.css';
@value _gray, _gray-light, _gray-lighter, _gray-dark from colors;
@value _ruby, _ruby-light, _ruby-dark from colors;
@value _blue-g, _blue-g-light, _blue-g-dark from colors;
@value _white from colors;
@value _bulletn-blue-light, _bulletn-blue, _bulletn-blue-dark from colors;
@value _steel, _steel-dark from colors;

.button,
.link {
  font-family: 'Lato', 'Open Sans', sans-serif;
  border-radius: 2px;
  font-weight: bold;
  font-size: 14px;
}

.styleless {
  background: none;
  padding: 0;
  border: none;
  line-height: 1;
  padding: 2px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.link:hover,
.link:focus {
  text-decoration: none;
}

.button:focus {
  outline: none;
}

.button svg {
  margin-right: 5px;
  vertical-align: middle;
}

/* Primary Buttons */
.primary {
  composes: button;
  background: _blue-g;
  color: _white;
  border: none;
}

.primary:hover,
.primary:focus {
  background: _blue-g-light;
}

.primary:active {
  background: _blue-g-dark;
}

/* Secondary Buttons */
.secondary {
  composes: button;
  background: _white;
  color: _bulletn-blue-light;
  border: 1px solid _gray;
}

.secondary:hover,
.secondary:focus {
  color: _bulletn-blue-light;
  border-color: _bulletn-blue-light;
}

.secondary:active {
  border-color: _bulletn-blue;
  background: _gray-light;
}

/* Tertiary Buttons */
.tertiary {
  composes: button;
  background: _white;
  color: _steel;
  border: 1px solid _gray;
}

.tertiary:hover,
.tertiary:focus {
  border-color: _steel;
}

.tertiary:active {
  border-color: _steel-dark;
  color: _steel-dark;
  background: _gray-lighter;
}

/* Destructive Buttons */
.destructive {
  composes: button;
  background: _ruby;
  color: _white;
  border: none;
}

.destructive:hover,
.destructive:focus {
  background: _ruby-light;
}

.destructive:active {
  background: _ruby-dark;
}

/* Disabled Buttons */
.button:disabled {
  background: _gray-lighter;
  color: _gray-dark;
}

/* Button Sizes */
.medium {
  padding: 10px 35px;
}

.mediumFixed {
  height: 40px;
  width: 150px;
}

.small {
  padding: 7px 25px;
}

.smallFixed {
  height: 35px;
  width: 127px;
}

.xsmall {
  padding: 4px 15px;
  font-size: 12px;
}

.xsmallFixed {
  height: 25px;
  width: 67px;
  font-size: 12px;
}

/* Button Group */
.buttonGroup :not(:last-child) {
  margin-right: 10px;
}
