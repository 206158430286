.sign-up-container,
.sign-up-container-with-special-message {
  background-color: white;
  width: 100%;
  height: 100%;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  left: 0;
  top: 0;
  z-index: 10;
}

.sign-up-container-with-special-message {
  height: calc(100% - 111px);
  top: 111px;
}

.sign-up-bounds {
  width: 100%;
  height: 550px;
  min-height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sign-up-bounds img {
  margin-bottom: auto;
}

.sign-up-fields-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 376px;
  min-width: 300px;
  margin-bottom: 50px;
  position: absolute;
}

.sign-up-fields-form-button-container {
  margin-top: auto;
  min-width: inherit;
  flex-direction: row-reverse !important;
}

.sign-up-fields-form-button-container .sign-up-next {
  margin-left: auto;
  width: 115px;
  height: 42px;
  padding-right: 0px;
  padding-left: 0px;
  transition: 0.5s;
}

.sign-up-fields-form-button-container .sign-up-previous {
  margin-right: auto;
  width: 115px;
  height: 20;
  padding-right: 0px;
  padding-left: 0px;
}

.sign-up-fields-form {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: inherit;
  align-items: center;
}

.success-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.success-screen-error {
  color: #fc5e54;
  text-align: center;
}

.success-screen-status {
  text-align: center;
}
